import React, { useState, useReducer, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import DayCalendar from "../../../../components/calendar/calendar-day";
import "./style.scss"
import PageHeader from "../../../../shared/PageHeader";
export default function DayViewDialog({ visible, onChange, data, onDialogClose, path, filteredData }) {



    const HeaderItems = [
        {
            id: 1,
            type: "legend",
            legendItems: path === 'location-schedule'
                ? ["Private", "Scheduled", "Fully Booked"]
                : ["Private", "Scheduled"]
        }

    ];

    const headerContent = () => (
        // <div className="flex justify-content-between event-view-dialog-header">
        //     <h1>{data?.event_type_name}</h1>
        // </div>
        <div className="flex align-items-center justify-content-between">
            <PageHeader
                HeaderItems={HeaderItems}
            />
            <i className="ic-close day_view_close_icon" onClick={onChange}></i>
        </div>
    )



    useEffect(() => {

    }, []);

    const onSuccess = () => {
        if (onDialogClose) {
            onDialogClose();
        }
    }







    return (
        <>
            <Dialog
                header={path == "location-schedule" || path == "my-reservation" ? headerContent : ""}
                className="sign_up_dialog day_view_dialog"
                headerStyle={{ textAlign: "left", color: "#212121", fontWeight: "500" }}
                visible={visible}
                style={{ width: "100%" }}
                position="center"
                draggable={false}
                resizable={false}
                closable={path == "location-schedule" || path == "my-reservation" ? false : true}
                onHide={onChange}
            >
                {<DayCalendar calendarDate={new Date(data?.occurrence_date)} filteredEventData={filteredData} path={path} />}
            </Dialog>
        </>
    );
}
