import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Dialog } from "primereact/dialog";
import "./style.scss";
import { Button } from "primereact/button";
const WaitListConfirmation = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    showPopUp() {
      setVisible(true);
    },
    hidePopUp() {
      setVisible(false);
    }
  }));

  const footerContent = (
    <div className="mt-3 flex justify-content-center align-items-center gap-3">
      <Button
        label="Cancel"
        onClick={() => props.onCancel()}
        className="btn-secondary w-10rem"
      />
      <Button label="Join Waitlist" onClick={() => props.onConfirm()} className="btn-primary w-10rem"/>
    </div>
  );

  return (
    <Dialog
      header={props.title}
      visible={visible}
      draggable={false}
      resizable={false}
      className="success_popup"
      style={{ width: "30vw" }}
      onHide={() => props.onHide()}
      footer={footerContent}
      closable={props?.closable ? false : true}
    >
          <div className="flex flex-column jsutify-content-center align-items-center gap-3">
              <p className="message-title">{props.messageTitle}</p>
      </div>
    </Dialog>
  );
});

export default WaitListConfirmation;