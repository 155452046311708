import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";


const TokenListDialog = ({ visible, data, onChange }) => {
    const [eventDetails, setEventDetails] = useState([])


    const headerContent = () => (
        <div className="flex justify-content-between event-view-dialog-header">
            <h1>Tokens</h1>
            <div className="flex gap-2">
                <i className="ic-close header-icon ml-2" onClick={() => onChange()} />
            </div>
        </div>
    )

    const onLoad = () => {
        setEventDetails(data)
    }

    const onHide = () => {
        confirmationPopupRef.current?.hidePopUp()
        onChange()
    }

    return (
        <>
            <Dialog
                className={`event-view-dialog`}
                visible={visible}
                //onHide={onHide}  
                modal
                resizable={false}
                header={headerContent}
                draggable={false}
                closable={false}
                onShow={onLoad}
                onHide={onChange}
                dismissableMask
            >
                <main className={`w-full mt-1  overflow-hidden  `}>
                    <section className="w-full">
                        <div className="w-full event-view-schedule gap-2">
                            <div className="w-full flex flex-column">
                                <div className="flex justify-content-between court-info">
                                    <div className="flex flex-column">
                                        {data?.map((item, index) => (
                                            <span key={index}>{item.total_tokens} {item.event_type}</span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </Dialog>
        </>
    );
}

export default TokenListDialog;