import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import FormSelect from "../Form-Select";
import './style.scss';
import { useEffect, useState } from "react";
import httpClient from "../../_util/api";


const AdvancedFilterSidebar = ({visible, setVisible, setFilterData}) => {

    const [eventTypes, setEventTypes] = useState();
    const [selectedEvent, setSelectedEvent] = useState('');

   const handleFilter = (data)=>{
    setFilterData(data);
    setVisible(!visible);
   }

   const handleReset = ()=>{
    setSelectedEvent('');
    setFilterData([]);
   }


    const fetchEventTypes = async () => {
        try {
          const { data } = await httpClient.get("/lookup-event-type");
          
          setEventTypes(data.data);
        } catch (error) {
          
        }
      };


      useEffect(()=>{
        fetchEventTypes();
      },[])



    const headerContent = ()=> (
        <div className="w-full advanced-filter-header flex justify-content-between px-2 pt-1 pb-3">
            <h1 className="text-lg m-0">Advanced Filter</h1>
            <i onClick={()=>{setVisible(!visible)}} className="ic-close align-self-center cursor-pointer" />
        </div>
    )

   


    return ( 
        <>
        <Sidebar className="advanced-filter-sidebar" header={headerContent} visible={visible} position="right" onHide={setVisible}>
                
        <div className="advanced-filter-main mt-2 px-1">
        <FormSelect
              error
              title="Event Type"
              placeholder="Select"
              type='multi-select'
              optionValue='id'
              optionLabel="name"
              options={eventTypes}
              panelClassName="calender-filter-multiselect"
              onSelect={(value) =>
                
                setSelectedEvent(value)
              }
              selectedOption={selectedEvent}
            />
            </div>

            <div className="w-full flex justify-content-center gap-2 mb-2">
                    <Button
                      label="Reset"
                      className="w-full btn-secondary"
                      onClick={handleReset}
                      type="button"
                      disabled={!selectedEvent || selectedEvent?.length===0}
                      />

                    <Button
                    onClick={()=>{handleFilter(selectedEvent)}}
                      label="Apply"
                      className="w-full btn-primary"
                      type="button"
                      disabled={!selectedEvent}
                      />
        </div>
            

            </Sidebar>
        </>
     );
}
 
export default AdvancedFilterSidebar;