import React, { useState } from "react";
import { AppMenu } from "./AppMenus";
import { Button } from "primereact/button";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from 'primereact/dialog';
import { showMobileBar, hideMobileBar } from '../redux/sidebarSlice';

export const AppMobileSideBr = () => {
    const userRole = useSelector((state) => state.mainSlice.role);
    const userInfo = useSelector((state) => state.mainSlice.userdetail);
    const showSideBar = useSelector((state) => state.sidebarSlice.showMobileSideBar);
    const dispatch = useDispatch();

    const headerContent = (
        <div className="flex flex-column">
            <i onClick={() => {dispatch(hideMobileBar())}} className="cursor-pointer align-self-end mt-3  pi pi-times"/>
            <div className="sidebr_header flex flex-column align-items-center gap-1">
            <img
                style={{ borderRadius: "50%" }}
                width={50}
                height={50}
                src={userInfo?.image_path ? userInfo?.image_path : "/images/profile-pic.png"}
                className="cursor-pointer"
            />
            <div className="flex flex-column align-items-center" style={{ wordBreak: 'break-word', textAlign: 'center' }}>
              <div className="text-sm">{userInfo?.first_name} {userInfo?.last_name}</div>
              <div className="text-xs" >{userInfo?.email}</div>
            </div>
        </div>

        </div>  
    );

    return (    
        <Dialog header={''} className="sidebar_dialog" visible={showSideBar} position={"left"} style={{ width: '75dvw', height: '100vh' }}
            draggable={false}
            closeOnEscape={true}
            resizable={false}>
            {headerContent}
            <AppMenu />
        </Dialog>

    );
};
