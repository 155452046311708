import React, { useState, useReducer, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "./style.scss"
import SlotReserveComponent from "./components";


export default function SlotReserveDialog({ visible, onChange, data, onDialogClose }) {
  

    
    const headerContent = () => (
    <div className="flex justify-content-between event-view-dialog-header">
      <h1>{data?.event_type_name}</h1>
    </div>
  )



  useEffect(() => {
       
  }, []);
  
  const onSuccess = () => {
    if (onDialogClose) {
      onDialogClose();
    }
  }
  
  
  


  

  return (
    <>
    <Dialog
      header={headerContent}
      className="sign_up_dialog"
      headerStyle={{ textAlign: "left", color: "#212121", fontWeight: "500" }}
      visible={visible}
      style={{ maxWidth: "540px", width: "100%" }}
      position="center"
      draggable={false}
      resizable={false}
      closable={false}
      onHide={onChange}
      >
        <SlotReserveComponent data={data} onChange={onChange} onDialogClose={onSuccess}/>
      </Dialog>
      </>
  );
}
