import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import "./style.scss";

const FromSelect = (props) => {
  const handleSelection = (value) => {
    //setSelectedOption(value);

    props.onSelect(value);
  };

  const handleAddButton = () => {
    if (props?.onAddButtonClick) {
      props.onAddButtonClick();
    }
  }

  const panelFooterTemplate = () => {
    return (
      <div className="flex align-items-center gap-3 pb-3 DDfooter_container">
        <Button
          icon="pi i-plus"
          className="ddFooterBtn"
          outlined
          aria-label="Filter"
          onClick={() => handleAddButton()}
        />
        <span>Add New</span>
      </div>
    );
  };
  const handleSyncClick = () => {
    if (props.onButtonClick) {
      props.onButtonClick();
    }
  };
  return (
    <div className={`flex flex-column ${props.ispinloc? "mr-2 pinloc-cont": "drop-sync"}  shis-select ${props.containerclass? "" : "gap-2"} ${props.widthClass? props.widthClass : ""}`}>
      {props.title && props.title !== '' && <label>{props.title} {props?.required && <span className="text-danger">*</span>}</label>}
      {props.type && props.type == "templateDD" ? (
        <Dropdown
          value={props.selectedOption}
          className={`w-full ${props.styleclass || ""} ${props.error && "error-input"
            }`}
          filter={props.filter ? props.filter : false}
          onChange={(e) => handleSelection(e.value)}
          options={props.options ? props.options : []}
          optionLabel={props.optionLabel ? props.optionLabel : "name"}
          optionValue={props.optionValue ? props.optionValue : "id"}
          placeholder={props.placeholder ? props.placeholder : "Select"}
          filterPlaceholder={props.filterPlaceholder ? props.filterPlaceholder : "Search"}
          panelFooterTemplate={panelFooterTemplate}
          disabled={props.disabled}
        />
      ) : props.type && props.type === "multi-select" ? (
        <MultiSelect 
        options={props.options ? props.options : []}
        value={props.selectedOption}
        onChange={(e) => handleSelection(e.value)}
        filter={true}
        selectAll={false}
        panelClassName={`${props?.panelClassName ? props.panelClassName : ''} ${props?.options?.length == 0 ? 'hide-selectall' : ''}`}
        resetFilterOnHide={true}
        optionLabel={props.optionLabel ? props.optionLabel : "name"}
        optionValue={props.optionValue ? props.optionValue : "id"}
        placeholder={props.placeholder ? props.placeholder : "Select"}
        filterPlaceholder={props.filterPlaceholder ? props.filterPlaceholder : "Search"}
        loading={props?.loading ? props?.loading : false}
        disabled={props?.disabled ? props.disabled : false}
        maxSelectedLabels={3}
        display='chip'
        className={`w-full ${props?.styleclass ? props.styleclass : ''}`} />
      ) : (
        <Dropdown
          value={props.selectedOption}
          className={`w-full ${props?.styleclass ? props?.styleclass : ''}`}
          filter={props.filter ? props.filter : false}
          resetFilterOnHide={true}
          loading={props?.loading ? props?.loading : false}
          //onFilter={(e) => props?.handleFilterChange(e.filter)}
          onChange={(e) => handleSelection(e.value)}
          options={props.options ? props.options : []}
          filterPlaceholder={props.filterPlaceholder ? props.filterPlaceholder : "Search"}
          optionLabel={props.optionLabel ? props.optionLabel : "name"}
          optionValue={props.optionValue ? props.optionValue : "id"}
          placeholder={props.placeholder ? props.placeholder : "Select"}
          disabled={props.disabled}
        />
      )}
    </div>
  );
};

export default FromSelect;
