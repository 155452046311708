import { Tooltip } from 'primereact/tooltip';
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const MenuLink = (props) => {
    const location = useLocation();
    const sidebarType = useSelector((state) => state.sidebarSlice.value);
    //const isActive = location.pathname == props.to;
    const isActive = location.pathname.includes(props.to);
    const headerActive = location.pathname.includes(props?.title?.toLowerCase())
    return(
        <Link to={props.to} className={`${props.classes} ${isActive ? 'active' : 'notactive'} ${headerActive && sidebarType == "half-bar" ? 'active' : "notactive"}`}>
            <span className={`p-menuitem-icon menu-link-${props.icon} ${props.icon}`} data-pc-section="headericon"></span>
            <span className={`p-menuitem-text menu-link-${props.icon}`} data-pc-section="headerlabel">{props.text}</span>
            {props.count !== undefined && (
                <span className={`p-menuitem-count menu-link-${props.icon} ${props.count.toString().length > 2 ? "small-no" : "large-no"}`}>
                    {parseInt(props.count.toString()) > 99 ? '99+' : props.count}
                </span>
            )}
            {sidebarType == "half-bar" && <Tooltip className={"custom-tooltip-menu"} target={`.menu-link-${props.icon}`} content={props.title} position="right" showDelay={100} hideDelay={100}/>}
        </Link>
    )
}

export default MenuLink;