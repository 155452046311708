import React, { useState } from "react";
import { AppMenu } from "./AppMenus";
import { Button } from "primereact/button";
import { useSelector, useDispatch } from "react-redux";
import { toggleSideBar } from "../redux/sidebarSlice";
import halfBarLogo from '../assets/images/halfbarlogo.png';
export const AppSideBr = ({ onBarStyleChange }) => {
  const sidebarType = useSelector((state) => state.sidebarSlice.value);
  const dispatch = useDispatch();

  // const [sidebarType, setsidebarType] = useState('full-bar');

  const handleBarViewChange = () => {
    dispatch(toggleSideBar());
    
  };

  const handleSideBarHide = () => {
    
  };

  return (
    <div className={`viv_sidebar ${sidebarType}`}>
      <div className={`p-sidebar p-component p-ripple-disabled p-sidebar-enter-done sideMenus_Bar ${sidebarType}`}>
        <div className="p-sidebar-content">
          <Button
            icon={sidebarType == "half-bar" ? "i-right-chev" : "i-left-chev"}
            className="btn_sidebrExpand"
            onClick={handleBarViewChange}
            rounded
            text
            raised
            aria-label="Filter"
          />
          <div className="sidebr_header">
            {/* <img src="/images/logo_sm.svg" /> */}
            {sidebarType == "full-bar" ?
              (<img src="/images/logo.png" />) : (<img className="half-bar-logo-img" src={halfBarLogo } />)
            }
            {/* <p>Your Company</p> */}
          </div>
          <AppMenu />
        </div>
      </div>
      {/* <Sidebar visible={true} position="left" className={`sideMenus_Bar ${sidebarType}`} dismissable={true} closeIcon={true} modal={false} onHide={handleSideBarHide}>
                <Button icon={sidebarType == "half-bar" ? "i-right-chev" : "i-left-chev"} className="btn_sidebrExpand" onClick={handleBarViewChange} rounded text raised aria-label="Filter" />
                <div className="sidebr_header">
                    <img src="/images/logo_sm.svg" />
                    <p>Scan2Receive</p>
                </div>
                <AppMenu />
            </Sidebar> */}
    </div>
  );
};
