import React, { useState } from "react";
import { Calendar } from 'primereact/calendar';

export default function CalenderRangeInput({ date, setDate, className = '', ...props }) {
    return (
        <Calendar {...props} value={date} onChange={(e) => setDate(e.value)}
            numberOfMonths={2}
            selectionMode="range"
            readOnlyInput
            className={
                className
            }
        />

    )
}