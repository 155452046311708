import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Panel } from 'primereact/panel';
import "./style.scss";
import { Button } from "primereact/button";
import PrivateSlotReserveComponent from "../private-slot-reserve-dialog/components";
import SlotReserveComponent from "../slot-reserve-dialog/components";
import { hideLoader, setEventSuccess, showLoader } from "../../../../redux/mainSlice";
import { useDispatch } from "react-redux";
import httpClient from "../../../../_util/api";
import VivToast from "../../../../shared/VivitechToast";
import { useLocation } from "react-router-dom";
import ReservationCancelComponent from "../reservation-cancel-dialog/components";

const SlotListDialog = ({ visible, onChange, data, onDialogClose }) => {
    const [eventsInfo, setEventsInfo] = useState([])
    const dispatch = useDispatch();
    const toast_Ref = useRef(null);
    const location = useLocation()
    
    const headerContent = ()=> (
        <div className="slot-event-dialog-header">
            <h1 className="slot-event-dialog-header-h1">All Event</h1>
        </div>
    )


    const headerTemplate = (options,event) => {
        const className = `${options.className} justify-content-space-between  ${event.fully_booked ? 'booked_event_header' : (event.event_type_separate === "scheduled_play") ? 'schedule_event_header' : 'private_event_header'}`;

        const toggleDetail = ()=>{
            options.togglerElement();
        }

        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <span className="slot-event-title">{event.event_type_name}</span>
                </div>
                <div className="flex gap-5">
                <div className="flex flex-row">
                        <p className="pl-2 pr-2  align-self-center token_cost ">{event.token_cost}</p>
                        <img className=" align-self-center" src="/images/tokenicon.svg" alt="" />
                        
                    </div>
                    {event.count && <div className={`court-count-wraper ${event.fully_booked ? 'booked_event_court-count-wraper' : (event.event_type_separate === "scheduled_play") ? 'schedule_event_court-count-wraper' : 'private_event_court-count-wraper'}`}>
                <p className="">{event.count}</p>
              </div>}
                  
                    {options.togglerElement}
                </div>
            </div>
        );
    };


    const onHide = () => {
        //confirmationPopupRef.current?.hidePopUp()
        onChange()
      }


      const onLoad = () => {
        setEventsInfo(data)
    }
    
    
    const onConfirmWaitList = async (eventPopupData) => {
        dispatch(showLoader())

        try {
            const resp = await httpClient.post("/wait-list", {
                event_id: eventPopupData.event_id,
                occurrence_date: eventPopupData.occurrence_date,
                start_time: eventPopupData.start_time,
                end_time: eventPopupData.end_time
            });

            if (resp.status == 200) {
                toast_Ref.current?.showMessage(
                    "success",
                    "You have successfully join wait list",
                    "",
                    "ic-square-check"
                )
                dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
                setTimeout(() => {
                    onChange()
                    if (onDialogClose) {
                        onDialogClose()
                    }
                }, 500)
            }

        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
        }
        dispatch(hideLoader())
    }




    return ( 
        <>
            <VivToast ref={toast_Ref} />
                <Dialog
                header={headerContent}
                className="viv_dialog"
                headerStyle={{ textAlign: "left", color: "#212121", fontWeight: "500" }}
                visible={visible}
                style={{ maxWidth: "700px", width: "100%" }}
                position="center"
                draggable={false}
                resizable={false}
                onShow={onLoad}
                onHide={onChange}
            >
                <div className="grid slot-event-list mt-2">
                    
                    {eventsInfo.map((event) => (

                <div key={event.id} className="col-12">
                        <Panel headerTemplate={(options) => headerTemplate(options,event)} toggleable className="slot-event-panel" collapseIcon='ic-chev-up' expandIcon='ic-chev-down' collapsed={true}>
                           
                                {event.event_type_separate === "scheduled_play" ? (
                                    !event.fully_booked ? (
                                        location.pathname.includes('location-schedule') ? (
                                            <SlotReserveComponent
                                                data={event}
                                                viewMore={true}
                                                onChange={onChange}
                                                onDialogClose={onDialogClose}
                                            />
                                        ) : (<>
                                                <ReservationCancelComponent
                                                    data={event}
                                                    viewMore={true}
                                                    onChange={onChange}
                                                    onDialogClose={onDialogClose}
                                                />
                                            </>)
                                    ) : (
                                        <div className="flex flex-column w-full gap-2 booked-info px-2 py-3">
                                            <h1>All Courts are booked. We have a waiting list of 12 members. Do you want to join the waitlist?</h1>
                                            <div className="flex gap-3 justify-content-end">
                                                <Button
                                                    className="w-full btn-primary text-white"
                                                    label="Join Waitlist"
                                                        onClick={() => onConfirmWaitList(event)}
                                                    disabled={false}
                                                />
                                            </div>
                                        </div>
                                    )
                                ) : (
                                        <PrivateSlotReserveComponent data={event} viewMore={true} onChange={onChange} onDialogClose={onDialogClose} />
                                )}
                        </Panel>
                    </div>

                    ))}
                    
                </div>
                
            </Dialog>




        </>
     );
}
 
export default SlotListDialog;