import React, { useRef, useState, useEffect } from "react";
import { Button } from "primereact/button";
import "../assets/layout/_notification.scss";
import { hideLoader, setNotificationCount, showLoader } from "../redux/mainSlice";
import { Badge } from "primereact/badge";
import { useDispatch, useSelector } from 'react-redux';
import httpClient from "../_util/api";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import VivToast from "../shared/VivitechToast";


const Notification = () => {
  const parentOp = useRef(null);
  const toast_Ref = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const notification_count = useSelector((state) => state.mainSlice.notification_count);
  const userRole = useSelector((state) => state.mainSlice.role);
  const [newNotifications, setNewNotifications] = useState([]);
  const [earlyNotifications, setEarlyNotifications] = useState([])

  const [visible, setVisible] = useState(false);

  const showSidebar = () => {
    setVisible(true);
    viewAllnotification();
  };

  const getNotificationsCount = async () => {
    let apiResp = await httpClient.get("whomi")

    if (apiResp?.status == 200) {
      dispatch(setNotificationCount(apiResp.data.data.user.unread ? apiResp.data.data.user.unread : 0));
    }
  }


  useEffect(() => {
    console.log(notification_count);
    
  }, [notification_count]);



  const detailview = (e) => {
    e.preventDefault()
    onHideNotifications()
    navigate("/notifications")
  };


  const handleInvitation = async (button, notificationId) => {

    const url = button === 'Reject'
      ? `invitation-decline/${notificationId}`
      : button === 'Reserved' ? `reservation-accept/${notificationId}` : `invitation-accept/${notificationId}`;


    try {
      dispatch(showLoader());
      const resp = await httpClient.get(url);
      if (resp && resp.status === 200) {
        toast_Ref.current?.showMessage(
          "success",
          resp?.data?.message,
          "",
          "ic-square-check"
        )
        viewAllnotification()
      }
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message,
        "",
        "ic-error-notify"
      );
    }
    dispatch(hideLoader());

  };


  const viewAllnotification = async () => {

    let resp = await httpClient.get(`notifications`).catch((error) => { dispatch(hideLoader()); });

    if (resp?.status == 200) {
      const notifications = resp?.data?.data.data
      setNewNotifications(notifications
        .filter(notification => notification.is_read === 0));
      setEarlyNotifications(notifications
        .filter(notification => notification.is_read === 1));
      console.log(resp?.data?.data.data)

    }
  }

  const markAllNotificationsAsRead = async () => {

    let resp = await httpClient.get(`mark-read`).catch((error) => { dispatch(hideLoader()); });

    if (resp?.data?.status == 200) {
      viewAllnotification()
    }

  }


  const onHideNotifications = async () => {
    setVisible(false)
    markAllNotificationsAsRead()
    getNotificationsCount();
  }


  const BookingConfirmation = (message) => {
    // Regular expressions for date and time patterns
    const datePattern = /\b(?:Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday),?\s\w+\s\d{1,2}\b/;
    const timePattern = /\b\d{1,2}:\d{2} ?[APMapm]{2} ?(?:to|-) ?\d{1,2}:\d{2} ?[APMapm]{2}\b/;
    // Highlight function
    const highlightText = (text) => {
      const highlightedText = text
        ?.replace(datePattern, '<span class="highlight">$&</span>')
        ?.replace(timePattern, '<span class="highlight">$&</span>');

      return { __html: highlightedText }; // For rendering HTML
    };

    return (
      <div>
        <p dangerouslySetInnerHTML={highlightText(message)}></p>
      </div>
    );
  };

  return (
    <>
      <VivToast ref={toast_Ref} />
      {/* <NotificationDialogBox
        visible={displayDialog}
        onHide={onHideDialog}
        dialogData={dialogData}
      /> */}

      <div className="card flex justify-content-center ">
        <div
          className="flex align-items-center"
          style={{ minWidth: "35px" }}
          onClick={showSidebar}
        >
          <i className="ic-bell p-overlay-badge cursor-pointer" style={{ fontSize: "18px" }}>

            {notification_count != 0 ? <Badge value={notification_count}></Badge> : ""}
          </i>
        </div>


        {/* Sidebar Notification Panel */}
        <Sidebar header={""} ref={parentOp} visible={visible} position="right" onHide={onHideNotifications} className="notification-panel">
          <div className="flex flex-row justify-content-between align-items-center noti-header-cont">
            <h3 className="noti-h3 m-0">Notifications</h3>
            <i className="ic-close cursor-pointer" onClick={() => { onHideNotifications() }}></i>
          </div>

          <section className="noti-section">
            {/* New Notifications */}
            {newNotifications.length > 0 && <> <p className="notification-header">New</p>
              {newNotifications
                .map((notification, index) => (
                  <div key={notification.id} className={`pt-3 noti-static flex flex-column new-notifications`}>
                    <h1 className="capitalize">{notification.title}</h1>

                    <div className="noti-static-msg flex flex-row justify-content-between">
                      <p>{BookingConfirmation(notification.message)}</p>
                      <span>{notification.time_diff}</span>
                    </div>

                    {/* Timeframe and Location display */}
                    {notification.start_time && notification.end_time && (
                      <div className="noti-timeloc-frame-msg mt-2 flex flex-row">
                        <div className="noti-time-frame-msg w-full flex flex-row justify-content-start gap-3 align-items-center">
                          <div className="flex justify-content-start align-items-center gap-2">
                            <i className="ic-clock"></i>
                            <span>{notification.start_time} - {notification.end_time}</span>
                          </div>
                          {notification.location && (
                            <>
                              <div className="flex justify-content-start align-items-center gap-2">
                                <i className="ic-location"></i>
                                <span>{notification.location}</span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Buttons */}
                    {notification.buttons && (
                      <div className="noti-static-btn-cont mt-3 flex flex-col gap-3">
                        {notification.buttons
                          .sort((a, b) => (a === "Reject" ? -1 : 1))
                          .map((button) => (
                          <>
                            <Button

                              label={button === "Reject" ? "Reject" : button}
                              className={`w-full ${button === "Reject" ? 'btn-tertiary' : 'btn-primary'}`}
                              onClick={() => handleInvitation(button, notification.id)}
                              type="button"
                            />
                          </>
                        ))}
                      </div>
                    )}
                    {<Divider />}
                  </div>
                ))}</>}


            {/* Earlier Notifications */}
            {earlyNotifications.length > 0 && <><p className="notification-header mt-2">Earlier</p>
              {earlyNotifications
                .slice(0, (newNotifications.length > 3 ? 2 : 5))
                .map((notification, index) => (
                  <div key={notification.id} className="noti-static flex flex-column early-notifications">
                    <h1 className="capitalize">{notification.title}</h1>
                    <div className="noti-static-msg flex flex-row justify-content-between">
                      <p>{BookingConfirmation(notification.message)}</p>
                      <span>{notification.time_diff}</span>
                    </div>
                    {/* Timeframe and Location display */}
                    {notification.start_time && notification.end_time && (
                      <div className="noti-timeloc-frame-msg mt-2 flex flex-row">
                        <div className="noti-time-frame-msg w-full flex flex-row justify-content-start gap-3 align-items-center">
                          <div className="flex justify-content-start align-items-center gap-2">
                            <i className="ic-clock"></i>
                            <span>{notification.start_time} - {notification.end_time}</span>
                          </div>
                          {notification.location && (
                            <>
                              <div className="flex justify-content-start align-items-center gap-2">
                                <i className="ic-location"></i>
                                <span>{notification.location}</span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {/* Buttons */}
                    {notification.buttons && (
                      <div className="noti-static-btn-cont mt-3 flex flex-col gap-3">
                        {notification.buttons
                          .sort((a, b) => (a === "Reject" ? -1 : 1))
                          .map((button) => (
                          <>
                            <Button

                              label={button === "Reject" ? "Reject" : button}
                              className={`w-full ${button === "Reject" ? 'btn-tertiary' : 'btn-primary'}`}
                              onClick={() => handleInvitation(button, notification.id)}
                              type="button"
                            />
                          </>
                        ))}
                      </div>
                    )}
                    {/* Divider between notifications */}
                    {<Divider />}
                  </div>
                ))}</>}


            {(earlyNotifications.length >= (newNotifications.length > 3 ? 2 : 5)) && <div className="text-center footer">
              <a href="" onClick={detailview}>View More</a>
            </div>}
          </section>
        </Sidebar>

      </div>
    </>
  );
};

export default Notification;
