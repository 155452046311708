import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import CalenderRangeInput from "./CalenderRangeInput";
import { Button } from "primereact/button";
import { locale, addLocale } from 'primereact/api';

export default function DateRangeModal({ visible, setVisible, date, setDate, apply, width = '50vw', height = '27vw' }) {
    const closeModal = () => {
        setVisible(false);
    }
    const onClear = () =>{
        setDate(null)
    }
    addLocale('en', {
        firstDayOfWeek: 1,
        dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    });

    locale('en');

    const onConfirm = (e) => {
        e.preventDefault();
        if (date && date[0] && date[1]) {
            apply();
            closeModal();
        }
    }

    const isDisabled = (date) => {
        if (date) {
            if (date[0]) {
                if (date[1]) {
                    return false;
                }
            }
        }
        return true;
    }

    const dateTemplate = (dateTemp) => {
        const isDateBetween = (startDate, endDate, checkDate) => {
            const startTimestamp = startDate.getTime();
            const endTimestamp = endDate.getTime();
            const checkTimestamp = new Date(checkDate.year, checkDate.month, checkDate.day).getTime(); // Adjust month to be zero-based

            return checkTimestamp > startTimestamp && checkTimestamp < endTimestamp;
        };
        let divData = dateTemp.day
        if (date) {
            if (date[0] || date[1]) {
                if (date[0] && date[1]) {
                    const dateDay1 = date[0].getDate();
                    const dateMonth1 = date[0].getMonth();
                    const dateYear1 = date[0].getFullYear();
                    if (dateDay1 == dateTemp.day && dateMonth1 == dateTemp.month && dateYear1 == dateTemp.year) {
                        divData = <div style={{ backgroundColor: '#FF6900', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', textAlign: 'center', width: '2.3rem', height: '2.3rem', lineHeight: '2em', padding: 0 }}>{dateTemp.day}</div>
                    }
                    const dateDay2 = date[1].getDate();
                    const dateMonth2 = date[1].getMonth();
                    const dateYear2 = date[1].getFullYear();
                    if (dateDay2 == dateTemp.day && dateMonth2 == dateTemp.month && dateYear2 == dateTemp.year) {
                        divData = <div style={{ backgroundColor: '#FF6900', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', textAlign: 'center', width: '2.3rem', height: '2.3rem', lineHeight: '2em', padding: 0 }}>{dateTemp.day}</div>
                    }
                    // for between dates
                    const result = isDateBetween(date[0], date[1], { day: dateTemp.day, month: dateTemp.month, year: dateTemp.year });
                    if (result) {
                        divData = <div style={{ backgroundColor: '#FFE1CD', color: '#FF6900', fontWeight: 'bold', borderRadius: '50%', textAlign: 'center', width: '2.3rem', height: '2.3rem', lineHeight: '2em', padding: 0 }}>{dateTemp.day}</div>
                    }
                }
                else {
                    if (date[0]) {
                        const dateDay = date[0].getDate();
                        const dateMonth = date[0].getMonth();
                        const dateYear = date[0].getFullYear();
                        if (dateDay == dateTemp.day && dateMonth == dateTemp.month && dateYear == dateTemp.year) {
                            divData = <div style={{ backgroundColor: '#FF6900', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', textAlign: 'center', width: '2.3rem', height: '2.3rem', lineHeight: '2em', padding: 0 }}>{dateTemp.day}</div>
                        }
                    }
                    if (date[1]) {
                        const dateDay = date[1].getDate();
                        const dateMonth = date[1].getMonth();
                        const dateYear = date[1].getFullYear();
                        if (dateDay == dateTemp.day && dateMonth == dateTemp.month && dateYear == dateTemp.year) {
                            divData = <div style={{ backgroundColor: '#FF6900', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', textAlign: 'center', width: '2.3rem', height: '2.3rem', lineHeight: '2em', padding: 0 }}>{dateTemp.day}</div>
                        }
                    }
                }
            }
        }
        return divData
    }

    return (
        <div className="flex justify-content-center align-items-center ">
            <Dialog id={'mydialog'} className="datefilter-modal" header={'Select Custom Date'} style={{ width: '40vw' }} visible={visible} modal={true} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={closeModal} closable closeOnEscape dismissableMask closeIcon={<i className="pi pi-times" style={{ fontSize: '1rem' }}></i>} draggable={false}>
                <div className="flex align-items-center align-items-center zoom-out">
                    <CalenderRangeInput
                        date={date}
                        setDate={setDate}
                        placeholder="yyyy-mm-dd"
                        inline
                        className="date-filter w-12"
                        yearNavigator yearRange="2018:2025"
                        dateTemplate={dateTemplate}
                    />
                </div>
                <div className="mt-3 flex justify-content-end align-items-center gap-3">
                <Button
                    label={"Clear"}
                    className="btn-secondary w-8rem"
                    onClick={onClear}
                  />
                    <form onSubmit={onConfirm}>
                    <Button
                  label={"Apply"}
                  className="btn-primary w-8rem"
                  disabled={isDisabled(date)}
                />

                    </form>
                </div>
            </Dialog>
        </div>
    )
}