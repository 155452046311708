import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import "./style.scss";
import AddEvent from "../addEvent";
import httpClient from "../../../../_util/api";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { useDispatch } from "react-redux";
import { hideLoader, setEventSuccess, showLoader } from "../../../../redux/mainSlice";
import VivToast from "../../../../shared/VivitechToast";
import ConfirmationPopup from "../../../../shared/ConfirmationPopup";

const EventViewDialog = ({ visible, data, onChange, type, onDialogClose }) => {
  const [eventDetails, setEventDetails] = useState([])
  const toast_Ref = useRef(null);
  const [editData, setEditData] = useState([])
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [is_all, setis_all] = useState(false)
  const dispatch = useDispatch();
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const confirmationPopupRef = useRef(null);
  const [showError, setShowError] = useState(false)
  const eventDetail = {
    title: "Summer Scheduled Play",
    time: "9 - 10",
    location: 'B01',
    courts: ["C1: R1, R2, R3, R4", "C2: R1, R2, R3, R4", "C3: R1, R2, R3, R4", "C4: R1, R2, R3, R4",]
  }

  const headerContent = () => (
    <div className="flex justify-content-between event-view-dialog-header">
      <h1>{eventDetails?.event_type_name}</h1>
      <div className="flex gap-2">
        <i className="ic-edit header-icon ml-2" onClick={() => onEditEvent(eventDetails)} />
        <i className="ic-delete header-icon ml-2" onClick={() => onDeleteEvent(eventDetails)} />
      </div>
    </div>
  )

  const onLoad = () => {
    setEventDetails(data)
  }


  const onEditEvent = async (data) => {
    dispatch(showLoader())
    try {
      const resp = await httpClient.post("/edit-slot", {
        event_id: data.event_id,
        occurrence_date: data.occurrence_date,
        start_time: data.start_time,
        end_time: data.end_time,
        court_id: data?.court_id
      });

      if (resp?.status === 200) {
        setEditData(resp.data.data)
        setShowAddEvent(true)
        onChange()
        if (onDialogClose) {
          onDialogClose()
        }
      }

    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
    }
    dispatch(hideLoader())
  }

  const onDeleteEvent = async (data) => {
    setDeleteData(data)

    if (data.is_recurrent === 1 || data.is_recurrent === true) {
      setDeleteConfirmDialog(true)

    } else {
      confirmationPopupRef.current.showPopUp()
    }

  }

  const onCheckIsAll = async () => {
    confirmationPopupRef.current.showPopUp()
  }

  const onHide = () => {
    confirmationPopupRef.current?.hidePopUp()
    onChange()
  }

  const onConfirm = async () => {
    dispatch(showLoader())
    try {
      const resp = await httpClient.post("/delete-slot", {
        event_id: deleteData.event_id,
        occurrence_date: deleteData.occurrence_date,
        start_time: deleteData.start_time,
        end_time: deleteData.end_time,
        court_id: deleteData?.court_id,
        is_all: is_all
      });

      if (resp?.status === 200) {
        dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
        toast_Ref.current?.showMessage(
          "success",
          "Reservation slot deleted successfully",
          "",
          "ic-square-check"
        )
        setTimeout(() => {
          confirmationPopupRef.current?.hidePopUp();
          setDeleteConfirmDialog(false)
          setis_all(false)
          onChange()
          if (onDialogClose) {
            onDialogClose()
          }
        }, 1000);

      }

    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
      confirmationPopupRef.current.hidePopUp()
    }
    dispatch(hideLoader())
  }


  const footerContentEditDialog = (
    <div className="mt-3 flex justify-content-end align-items-center gap-3">
      <Button
        label="Cancel"
        onClick={() => setDeleteConfirmDialog(false)}
        className="btn-secondary w-8rem"
      />
      <Button label="Ok" onClick={onCheckIsAll} className="btn-primary w-8rem" />
    </div>
  );


  const eventViewFooterContent = (
    showError ? <div className="mt-3 flex justify-content-center align-items-center gap-3">
      <p className="error-message-view-event"><i className="ic-exclamation-circle"></i> You can not edit the reservation slot <br /> &nbsp;&nbsp;&nbsp;&nbsp; as court 1 has been booked.</p>
    </div> : (eventDetails?.courts ? <div className="event-list-footer pt-5 mt-1"> </div> : <div></div>)
  );




  return (
    <>
      <VivToast ref={toast_Ref} />
      
      <ConfirmationPopup
        ref={confirmationPopupRef}
        closable={true}
        title="Confirm"
        message={is_all ? "Are you sure you want to delete the reservation slots?" : "Are you sure you want to delete this reservation slot?"}
        onConfirm={onConfirm}
        onCancel={() => confirmationPopupRef.current.hidePopUp()}
        onHide={() => confirmationPopupRef.current.hidePopUp()}// Hide popup on "No"
      />
      {/* <SuccessPopup
      ref={successPopupRef}
        title=""
        message={""}
        messageTitle={"Reservation slot deleted successfully"}
        onHide={() => successPopupRef.current?.hidePopUp() } /> */}
      <Dialog
        header={deleteData.event_type_name}
        visible={deleteConfirmDialog}
        draggable={false}
        className="success_popup edit_is_all_dialog"
        style={{ width: "33vw" }}
        onHide={() => setDeleteConfirmDialog(false)
        }
        footer={footerContentEditDialog}
        closable={true}
      >
        <div className="flex justify-content-start align-items-center gap-2">
          <RadioButton
            inputId="is_all"
            name="is_all"
            value={false}
            onChange={(value) => setis_all(value.value)}
            checked={is_all === false}
          />
          <label htmlFor="never" className="ml-2 text-base">This event</label>
          <RadioButton
            inputId="is_all"
            name="is_all"
            value={true}
            className="ml-3"
            onChange={(value) => setis_all(value.value)}
            checked={is_all === true}
          />
          <label htmlFor="never" className="ml-2 text-base">This & Following Events</label>
        </div>
      </Dialog>
      
      <AddEvent visible={showAddEvent} onChange={() => setShowAddEvent(false)} data={editData} type={type} />
      
      <Dialog
        className={`event-view-dialog ${eventDetails?.courts ? 'no-court-event-view ' : ''}`}
        visible={visible}
        //onHide={onHide}  
        modal
        resizable={false}
        header={headerContent}
        footer={eventViewFooterContent}
        draggable={false}
        closable={false}
        onShow={onLoad}
        onHide={onChange}
        dismissableMask
      >

        <main className={`w-full mt-1  overflow-hidden  `}>

          <section className="w-full flex justify-content-between  mt-1 overflow-hidden pt-1">

            <div className="event-view-schedule gap-2">

              <i className="ic-clock" />

              <div className="flex flex-column">
                <p className="">Time</p>
                <span> {eventDetails?.start_time?.split(":")[0]} - {eventDetails?.end_time?.split(":")[0]} {eventDetails?.end_time?.split(" ")[1]} </span>
              </div>

            </div>

            <div className="event-view-schedule gap-2 ml-3">

              <i className="ic-location" />

              <div className="flex flex-column">
                <p className="">Location</p>
                <span> {eventDetails?.location_name} </span>
              </div>

            </div>

          </section>

          {eventDetails?.slots != null && (<section className="w-full mt-4 ">
            <div className="w-full event-view-schedule gap-2 ">

              <i className="ic-court" />

              <div className="w-full flex flex-column">
                <p className="">Assigned Courts</p>

                <div className="flex justify-content-between court-info">

                  <div className="flex flex-column">
                    {eventDetails.slots && eventDetails.slots.map((court, index) => (
                      <span key={index}>{court.court_number}{index !== eventDetails.slots.length - 1 ? ' ' : ''} </span>
                    ))}
                  </div>

                  {/* <div className="flex flex-column">
        <i className="ic-check mt-1" />
        <i className="ic-check mt-2" />
        <i className="ic-check mt-2" />
        <i className="ic-check mt-2" />
    </div> */}

                </div>

              </div>

            </div>

          </section>)}


          {eventDetails?.courts != null && (<section className="w-full mt-4">
            <div className="w-full event-view-schedule gap-2">

              <i className="ic-court" />

              <div className="w-full flex flex-column">
                <p className="">Assigned Courts</p>

                <div className="flex justify-content-between court-info">

                  <div className="flex flex-column">
                    {eventDetails.courts && eventDetails.courts.map((court, index) => (
                      <span key={index}>{court.court_number}{index !== eventDetails.courts.length - 1 ? ' ' : ''} </span>
                    ))}




                  </div>

                  {/* <div className="flex flex-column">
        <i className="ic-check mt-1" />
        <i className="ic-check mt-2" />
        <i className="ic-check mt-2" />
        <i className="ic-check mt-2" />
    </div> */}

                </div>

              </div>

            </div>

          </section>)}

        </main>

      </Dialog>
    </>
  );
}

export default EventViewDialog;