import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import "./style.scss";
import { Button } from "primereact/button";
import ReservationCancelComponent from "./components";

const ReservationCancelDialog = ({ visible, data, onChange, type, onDialogClose }) => {
  const [eventDetails, setEventDetails] = useState([])
  const confirmationPopupRef = useRef(null);


  const headerContent = () => (
    <div className="flex justify-content-between align-items-center reservation-cancel-dialog-header">
      <h1 className="">{eventDetails?.event_type_name}</h1>
      <i onClick={onHide} className="ic-close" />

    </div>
  )

  const onLoad = () => {
    setEventDetails(data)
  }

  const onHide = () => {
    confirmationPopupRef.current?.hidePopUp()
    onChange()
  }

  // const footerContent = (
  //   <div className=" flex justify-content-end align-items-center gap-3 w-full event-list-footer pt-5 mt-1  ">
  //     <div className="reservation-cancel-footer-btn">
  //       <Button
  //         label="Close"
  //         onClick={onHide}
  //         className="btn-secondary w-full"
  //       />
  //     </div>
  //     <div className="reservation-cancel-footer-btn">
  //       <Button label="Cancel Reservation" /* onClick={() => confirmationPopupRef.current.showPopUp()} */ className="btn-primary w-full" />
  //     </div>
  //   </div>
  // );







  return (
    <>
      <Dialog
        className={`reservation-cancel-dialog ${eventDetails?.courts ? 'no-court-reservation-cancel ' : ''}`}
        visible={visible}
        //onHide={onHide}  
        modal
        resizable={false}
        header={headerContent}
        footer={""}
        draggable={false}
        closable={false}
        onShow={onLoad}
        onHide={onChange}
        dismissableMask
      >

        <ReservationCancelComponent data={data} onChange={onChange} />
      </Dialog>
    </>
  );
}

export default ReservationCancelDialog;