import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ProtectedRoute from "../_util/ProtectedRoute";
import ProtectedLogin from "../_util/ProtectedLogin";
import ProtectedSignUp from "../_util/ProtectedSignUp";
import PageLoader from "../core/FullPageLoader";
import Payment from "../pages/Member/wallet/payment";
import LocationSchedule from "../pages/Member/location-schedule/locationSchedule";
import NotificationDetailView from "../pages/notification-detail";
import MyReservation from "../pages/Member/my-reservation/myReservation";

// Lazy loading components
const Login = lazy(() => import("../pages/auth/login"));
const Dashboard =lazy(()=>import("../pages/Dashboard"))
const NotAllowed = lazy(() => import("../pages/error/unauthorizeduser"));

const Signup = lazy(() => import("../pages/auth/signup"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPass"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPass"));

const UserProfile = lazy(() => import("../pages/user-profile/userProfile"));
const FranchiseCalender = lazy(() => import("../pages/Franchise/calendar/calendar"));
const TokenStatistics = lazy(() => import("../pages/Franchise/token-statistics/tokenStatistics"));
const GiftToken = lazy(() => import("../pages/Franchise/gift-token/list"));
const MemberActivityList = lazy(() => import("../pages/Franchise/member-activity/list"));
const MemberActivityDetail = lazy(() => import("../pages/Franchise/member-activity/detail"));
const EventType = lazy(() => import("../pages/event-type/list"));
const EventTypeDetail = lazy(() => import("../pages/event-type/detail"));
const RoleForm = lazy(() => import("../pages/settings/roles/form"));
const RoleList = lazy(() => import("../pages/settings/roles/list"));
const UsersList = lazy(() => import("../pages/settings/users/list"));
const UsersForm = lazy(() => import("../pages/settings/users/form"));
const PaddleBuddyList = lazy(() => import("../pages/paddle-buddy/list"));
const PaddleBuddyDetail = lazy(() => import("../pages/paddle-buddy/detail"));
const Wallet = lazy(()=> import("../pages/Member/wallet/wallet"));

export const AppRoutes = (props) => {
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  
  const IsAllowedToRender = (module, func, route_type) => {
    let moduleDetail = userRoles.find(r => r.moduleName?.toLowerCase() == module);
    if(moduleDetail)
      {
        //let func_name = func == "user" ? "users" : curr_location[1] == "carrier" ? "carriers" : curr_location[1] == "role" ? "roles" : curr_location[1];
        let role_function = moduleDetail.functions.find(x => x.functionName?.toLowerCase() == func);
        if(route_type.includes("detail") || route_type.includes("list") || route_type == "view"){
          return role_function?.actions[0]?.value;
        }
        else if(route_type == "form" &&role_function.functionName==="Upload RMA"){
          return role_function?.actions[1]?.value;
        }
        else if(route_type == "form" &&role_function.functionName==="Stock Transfer"){
          return role_function?.actions[2]?.value;
        }
        else if(route_type == "form" &&role_function.functionName==="Pick"){
          return role_function?.actions[4]?.value;   
        }
        else if(route_type == "form"){
          return role_function?.actions[3]?.value;
        }
         else if(route_type == "form-edit" || role_function.functionName==="Pack" ){
          return role_function?.actions[4]?.value;
        }
        else if(route_type == "form-edit"){
          return role_function?.actions[1]?.value;
        }
      }
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<ProtectedLogin><Login /></ProtectedLogin>}></Route>
        <Route path="login" element={<ProtectedLogin><Login /></ProtectedLogin>}></Route>
        <Route path="login/admin" element={<Login />}></Route>
        <Route path="login/franchisee" element={<Login />}></Route>
        <Route path="signup/:id" element={<ProtectedSignUp><Signup /></ProtectedSignUp>}></Route>
        <Route path="forgotpassword" element={<ProtectedLogin><ForgotPassword /></ProtectedLogin>}></Route>
        <Route path="password-reset" element={<ResetPassword />}></Route>
        <Route path="AccessNotAllowed" element={<NotAllowed />}></Route>
        <Route path="profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>}></Route>
        <Route path="franchise/calendar" element={<ProtectedRoute><FranchiseCalender /></ProtectedRoute>}></Route>
        <Route path="/calendar" element={<ProtectedRoute><FranchiseCalender /></ProtectedRoute>}></Route>
        <Route path="franchise/token-statistics" element={<ProtectedRoute><TokenStatistics /></ProtectedRoute>}></Route>
        <Route path="token-statistics" element={<ProtectedRoute><TokenStatistics /></ProtectedRoute>}></Route>
        <Route path="franchise/gift-token" element={<ProtectedRoute><GiftToken /></ProtectedRoute>}></Route>
        <Route path="gift-token" element={<ProtectedRoute><GiftToken /></ProtectedRoute>}></Route>
        <Route path="franchise/member-activity" element={<ProtectedRoute><MemberActivityList/></ProtectedRoute>}></Route>
        <Route path="franchise/member-activity/detail/:id" element={<ProtectedRoute><MemberActivityDetail /></ProtectedRoute>}></Route>
        <Route path="member-activity" element={<ProtectedRoute><MemberActivityList/></ProtectedRoute>}></Route>
        <Route path="member-activity/detail/:id" element={<ProtectedRoute><MemberActivityDetail /></ProtectedRoute>}></Route>
        <Route path="settings/role" element={<ProtectedRoute><RoleList /></ProtectedRoute>}></Route>
        <Route path="settings/role/detail/:id" element={<ProtectedRoute><RoleForm /></ProtectedRoute>}></Route>
        <Route path="settings/role/form" element={<ProtectedRoute><RoleForm /></ProtectedRoute>}></Route>
        <Route path="settings/role/form/:id" element={<ProtectedRoute><RoleForm /></ProtectedRoute>}></Route>
        <Route path="event-type" element={<ProtectedRoute><EventType /></ProtectedRoute>}></Route>
        <Route path="event-type/detail/:id" element={<ProtectedRoute><EventTypeDetail /></ProtectedRoute>}></Route>
        <Route path="settings/user" element={<ProtectedRoute><UsersList /></ProtectedRoute>}></Route>
        <Route path="settings/user/detail/:id" element={<ProtectedRoute><UsersForm /></ProtectedRoute>}></Route>
        <Route path="settings/user/form" element={<ProtectedRoute><UsersForm /></ProtectedRoute>}></Route>
        <Route path="settings/user/form/:id" element={<ProtectedRoute><UsersForm /></ProtectedRoute>}></Route>
        <Route path="private-contacts" element={<ProtectedRoute><PaddleBuddyList /></ProtectedRoute>}></Route>
        <Route path="private-contacts/detail/:id" element={<ProtectedRoute><PaddleBuddyDetail /></ProtectedRoute>}></Route>
        <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}></Route>
        <Route path="wallet" element={<ProtectedRoute><Wallet /></ProtectedRoute>}></Route>
        <Route path="wallet/purchase" element={<ProtectedRoute><Wallet /></ProtectedRoute>}></Route>
        <Route path="payment" element={<ProtectedRoute><Payment /></ProtectedRoute>}></Route>
        <Route path="schedule-play" element={<ProtectedRoute><LocationSchedule /></ProtectedRoute>}></Route>
        <Route path="notifications" element={<ProtectedRoute><NotificationDetailView /></ProtectedRoute>}></Route>
        <Route path="my-reservations" element= { <ProtectedRoute> <MyReservation/> </ProtectedRoute> }/>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>

  );
};